/* global tumarket */

import "./layout.js";
import home from "../home/home.js";
import min from "../search/miniature.js";

//css
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

import "../../css/modules/tu.m/home/home.css";
import "../../css/modules/ctuCloud.css";

window.addEventListener('load', () => {
	home.init({
		isAutoPlay: tumarket.home.slider.isAutoPlay,
		isDotsEach: tumarket.home.slider.isDotsEach,
		autoplayTimeout: tumarket.home.slider.autoplayTimeout,
		showOtherCities: tumarket.home.showOtherCtities
	});
	
	min.init({
		IsAuth: tumarket.isAuthenticated
	});
});
