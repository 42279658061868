﻿import * as $ from "jquery";
import "readmore-js";
import { ajaxSend } from "../global/fetch";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { youtubePreview } from "../youtubePreview.js";
import { initTuPopover } from "../global/popover";
import tu_slider from "../slider.js";
import { showAlert } from "../global/dialog";

import "../../css/modules/previews/previewItem.css";

var previews = {
	options: {
		container: '', // селектор элемента с классом collapse. Не может быть "#previewsContainer", потому что такой занят в шаблоне с обзорами
		disabled: false,
		url: {
			getPreviews: '/preview/getPreviewsEmbed'
		},
		pageCode: null,
		limit: null
	},
	init: function(options) {
		this.options = $.extend(this.options, options);

		this.initCallbacks();

		//сразу загружаем обзоры, если collapse развернут
		if (this.options.container) {
			var $collapse = $(this.options.container);
			if ($collapse.hasClass("show")){
				var params = {
					productID: $collapse.attr("data-productid"),
					firmID: $collapse.attr("data-firmid"),
					previewIDs: $collapse.attr("data-previewids"),
					pageCode: $collapse.attr("data-pagecode"),
					limit: $collapse.attr("data-limit")
				};

				this.getPreviews(params);
			}
		}

		youtubePreview.init();
	},
	initCallbacks: function() {
		let self = this;

		if (this.options.container) {
			$(document).on('show.bs.collapse', this.options.container, function() {
				var params = {
					productID: $(this).attr("data-productid"),
					firmID: $(this).attr("data-firmid"),
					previewIDs: $(this).attr("data-previewids"),
					pageCode: $(this).attr("data-pagecode"),
					limit: $(this).attr("data-limit")
				};

				self.getPreviews(params);
			});
		}
	},
	_getPreviews: function(params) { // { productID: 0, firmID: 0, previewIDs: "" }
		if (this.options.disabled) return;

		var cont = document.querySelector("#previewsContainer");

		if (cont.innerHTML) return;

		ajaxSend({ url: this.options.url.getPreviews, data: params }).then(data => {
			if (data.result) {
				var tmpl = getPrecompiledTemplate('previews');
				let _data = JSON.parse(data.JsonData);
				_data.previews = data.items;
				var htmlOut = tmpl(_data);

				cont.innerHTML = htmlOut;

				initTuPopover($(cont));
				//youtubePreview.initPreviews(cont);
				tu_slider.create({
					container: document.querySelector('[data-previews-slider]')
				});
			}
			else {
				showAlert("Ошибка загрузки обзоров", { type: "danger" });
			}
		});
	},
	getPreviews: function(params){
        loadPrecompiledTemplates(['previews']).then(() => this._getPreviews(params));
    }
};

export default previews;